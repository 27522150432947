<template>
  <div class="Addinbound">
      <div class="info">
        <h1>新增入库单</h1>
        <a-row class="form-list">
          <a-col :span="12">
            <div class="add-form">
              <div class="single">
                <label>入库单编号：</label>
                <p>RK20210721386083</p>
              </div>
              <div class="single">
                <label><i>*</i>入库类型：</label>
                <a-select>
                  <a-select-option value="类型1">类型1</a-select-option>
                  <a-select-option value="类型2">类型2</a-select-option>
                  <a-select-option value="类型3">类型3</a-select-option>
                </a-select>
              </div>
              <div class="single">
                <label>生产工单号：</label>
                <a-input placeholder="请输入" />
              </div>
              <div class="single">
                <label>入库仓库：</label>
                <a-select>
                  <a-select-option value="类型1">类型1</a-select-option>
                  <a-select-option value="类型2">类型2</a-select-option>
                  <a-select-option value="类型3">类型3</a-select-option>
                </a-select>
              </div>
              <div class="single">
                <label>备注：</label>
                <a-textarea placeholder=""/>
              </div>
              <div class="single">
                <a-button type="primary">保存</a-button>
                <a-button>返回</a-button>
              </div>
            </div>
          </a-col>
        </a-row>
        <h2>入库明细 <a-button type="primary">选择入库明细</a-button></h2>

        <a-table :columns="columns" :data-source="data" :pagination="pagination">
          <span slot="num" class="count-ipt">
            <a-input placeholder="请输入" />
          </span>
          <span slot="place">
            <a-input placeholder="请输入" />
          </span>
          <span slot="action">
            <a>移除</a>
          </span>
        </a-table>
      </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '物品名称',
    dataIndex: 'itemName',
  },
  {
    title: '物品编码',
    dataIndex: 'itemCode',
  },
  {
    title: '物品类型',
    dataIndex: 'itemType',
  },
  {
    title: '入库数量',
    scopedSlots: { customRender: 'num' },
  },
  {
    title: '入库位置',
    scopedSlots: { customRender: 'place' },
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [
  {
    key: '1',
    itemName:'GT300型号GPS导航仪',
    itemCode:'#DFD#',
    itemType:'产品'
  },
];
export default {
  data() {
    return {
      data,
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
  .Addinbound{
    padding: 30px;

    .info{
      background-color: #ffffff;
      padding: 30px;

      h1{
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
      }
      .form-list{
        margin-bottom: 50px;

        .add-form{
          margin: 30px 0;

          .single{
            margin-bottom: 20px;

            label{
              display: inline-block;
              font-size: 14px;
              color: #333333;
              width: 100px;
              text-align: right;
              margin-right: 15px;

              i{
                color: #d9001b;
              }
            }
            
            p{
              width: 80%;
              display: inline-block;
            }
            
            .ant-select{
              width: 80%;
            }
            .ant-input{
              width: 80%;
            }
            
            .ant-btn{
              margin-right: 10px;
            }
          }

          .single:last-child{
            text-align: center;
            margin-top: 30px;
          }
        }
      }

      h2{
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;

        .ant-btn{
          margin-left: 10px;
        }
      }

      .count-ipt{

        .ant-input{
          width: 30%;
        }
      }
    }
  }
</style>
